import {NftCollection} from '@/model/resource/NftCollection'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import NftCollectionMonthVolumeRender from '@/components/render/NftCollectionMonthVolumeRender.vue'
import NftCollectionCellRender from '@/components/render/NftCollectionCellRender.vue'

export class SimpleListNftCollectionSchema extends DefaultSchema {
  readonly name = 'ListNftCollection'

  readonly orderByList = [
    'name',
    'averagePriceWeek',
    'weekPriceChange',
    'lowestPrice',
    'totalSupply',
    'monthVolume',
  ]

  readonly fieldSet: FieldSet<NftCollection> = {
    metadata: (schema): FieldComponent => ({
      is: NftCollectionCellRender,
      bind: {
        nftCollection: schema.model,
      },
    }),
    averagePriceWeek: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.averagePriceWeekFormatted,
        class: 'text-md sm:text-lg',
      },
    }),
    weekPriceChange: (schema): FieldComponent => ({
      is: PercentageProgressRender,
      bind: {
        value: schema.model.weekPriceChange,
        inputClass: 'text-md sm:text-lg',
        inputClassBorder: 'w-6 h-6',
        showArrows: true,
        rounded: true,
      },
    }),
    lowestPrice: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.lowestPriceFormatted,
        class: 'text-md sm:text-lg',
      },
    }),
    totalSupply: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.totalSupplyFormatted,
        class: 'text-md sm:text-lg',
      },
    }),
    monthVolume: (schema): FieldComponent => ({
      is: NftCollectionMonthVolumeRender,
      bind: {
        volume: schema.model.monthVolumeChange,
        price: schema.model.monthVolumePriceFormatted,
        inputClass: 'text-md sm:text-lg',
      },
    }),
  }
}
